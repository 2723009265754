

//Bootrap JS


//GDPR Cookies
var options = {
    // title: '&#x1F36A; Accept Cookies & Privacy Policy?',
    title: 'Accept Cookies & Privacy Policy?',
    message: 'We use cookies to give you the best experience on our website. You may Accept the cookies below or Customize them to suit your preferences. For more information please visit our',
    delay: 600,
    expires: 30,
    link: '/privacy-policy',
    onAccept: function(){
        var myPreferences = $.fn.ihavecookies.cookie();
        // console.log('Yay! The following preferences were saved...');
        // console.log(myPreferences);
    },
    uncheckBoxes: true,
    acceptBtnLabel: 'Accept Cookies',
    moreInfoLabel: 'Privacy Policy',
    cookieTypesTitle: 'Select which cookies you want to accept',
    fixedCookieTypeLabel: 'Essential',
    fixedCookieTypeDesc: 'These are essential for the website to work correctly.'
}

//JQUERY for navigation
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('nav').outerHeight() - 1.2;

//JQuery Module Pattern

// An object literal
var app = {
    init: function() {
        app.functionOne();
    },
    functionOne: function () {
    }
};





$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();


    // Secondary navigation events
    if($("#navbarSecondary").length > 0){
        $(document).on("scroll", onScroll);
        
        //smoothscroll
        $('a[href^="#"]').on('click', function (e) {
            e.preventDefault();
            $(document).off("scroll");
            $('a').each(function () {
                $(this).removeClass('active');
            })
            $(this).addClass('active');
            var target = this.hash,
                menu = target;
            $target = $(target);
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top+2
            }, 500, 'swing', function () {
                window.location.hash = target;
                $(document).on("scroll", onScroll);
            });
        });
        function onScroll(event){
            var scrollPos = $(document).scrollTop();
            $('#navbarSecondary a').each(function () {
                var currLink = $(this);
                var refElement = $(currLink.attr("href"));
                if (refElement.position().top - 200 <= scrollPos && refElement.position().top  + refElement.height() > scrollPos) {
                    $(".secondary-nav-link").removeClass("active");
                    currLink.addClass("active");
                }
                else{
                    currLink.removeClass("active");
                }
            });
        }
        
    }

    // Chat Box events
    if($('.banner-wrapper').length > 0){
        var bannerHeight = $('.banner-wrapper').outerHeight();
        $("#chatBtnTxt").show(); //hide your div initially
        var bannerWrapper = $(".banner-wrapper");
        if(bannerWrapper.length){
            var topOfTheDiv = bannerWrapper.offset().top + (bannerHeight / 2);
        }
        $(window).scroll(function() {
            if($(window).scrollTop() > topOfTheDiv) { //scrolled past the other div?
                $("#chatBtnTxt").hide(); //reached the desired point -- show div
            }else{
                $("#chatBtnTxt").show();
            }
        });
    }

    // Social Bar event
    if($(".blog-post-banner").length > 0){
        var blogPostBannerHeight = $(".blog-post-banner").outerHeight();
        var blogBodyHeight = $(".blog-body").outerHeight();
        $("#socialBar").hide(); //hide your div initially
        var bannerWrapper = $(".blog-post-banner");
        if(bannerWrapper.length){
            var topOfTheDiv = bannerWrapper.offset().top + (blogPostBannerHeight / 2);
        }
        var bottomDiv = $(".blog-body");
        if(bottomDiv.length){
            var bottomOftheDiv = bottomDiv.offset().top + (blogBodyHeight / 2);
        }
    
        $(window).scroll(function() {
            if($('#recentArticles').length > 0){
                var hT = $('#recentArticles').offset().top,
                hH = $('#recentArticles').outerHeight(),
                wH = $(window).height(),
                wS = $(this).scrollTop();
                if (wS > ((hT+hH-wH) - 400)){
                    $("#socialBar").fadeOut();
                }else{
                    $("#socialBar").fadeIn(); //reached the desired point -- show div
                }
            }
    
        });
    }


    
});
$(window).scroll(function(event){
    didScroll = true;
});
setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);


function hasScrolled() {
    var st = $(this).scrollTop();
    
    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;
    
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('nav').removeClass('nav-down').addClass('nav-up');
        $("#navbarSecondary").css('top', 0);
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('nav').removeClass('nav-up').addClass('nav-down');
            $("#navbarSecondary").css('top', navbarHeight);
        }
    }
    
    lastScrollTop = st;
}


// JQUERY for Mutlitple Dropdown
(function($){
    $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
        $('.dropdown-submenu .show').removeClass("show");
    });

    return false;
    });
})(jQuery)




$(document).ready(function() {
    $('body').ihavecookies(options);

    if ($.fn.ihavecookies.preference('marketing') === true) {
        // console.log('This should run because marketing is accepted.');
    }

    $('#ihavecookiesBtn').on('click', function(){
        $('body').ihavecookies(options, 'reinit');
    });
});

